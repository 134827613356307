"use client";

import { useLocalStorage } from "@mantine/hooks";
import { auth } from "../firebase";

export const useImpersonation = () => {
  const [isImpersonating, setImpersonating] = useLocalStorage<boolean>({
    key: "is-impersonating",
  });
  const [impersonationExpiry, setImpersonationExpiry] = useLocalStorage<number>({
    key: "impersonation-dialog-expiry",
  });

  const dismissImpersonationDialog = () => {
    const anHourFromNow = new Date().getTime() + 1000 * 60 * 60;
    setImpersonationExpiry(anHourFromNow);
  };

  const signOut = async () => {
    setImpersonating(false);
    setImpersonationExpiry(0);
    await auth().signOut();
    window.location.assign("/sign-in");
  };

  const startImpersonation = () => {
    setImpersonating(true);
    setImpersonationExpiry(0);
  };

  return {
    startImpersonation,
    signOut,
    isImpersonating,
    impersonationExpiry,
    dismissImpersonationDialog,
  };
};
